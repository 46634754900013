var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ReportEntityDetails__Component" },
    [
      _c("FieldInlineText", {
        attrs: { label: "סטטוס", readOnly: true },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Status,
          callback: function($$v) {
            _vm.$set(_vm.model, "Status", $$v)
          },
          expression: "model.Status"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "שם לקוח", readOnly: true },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.ProfileName,
          callback: function($$v) {
            _vm.$set(_vm.model, "ProfileName", $$v)
          },
          expression: "model.ProfileName"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "אסמכתה (ירושלים)", readOnly: false },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.MuniReferenceNumber,
          callback: function($$v) {
            _vm.$set(_vm.model, "MuniReferenceNumber", $$v)
          },
          expression: "model.MuniReferenceNumber"
        }
      }),
      _c("FieldInlineText", {
        attrs: {
          label: "סכום מקורי",
          required: "true",
          readOnly: _vm.readOnly
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.OriginalAmount,
          callback: function($$v) {
            _vm.$set(_vm.model, "OriginalAmount", $$v)
          },
          expression: "model.OriginalAmount"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "סכום ששולם", readOnly: true },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.PartialPaidAmount,
          callback: function($$v) {
            _vm.$set(_vm.model, "PartialPaidAmount", $$v)
          },
          expression: "model.PartialPaidAmount"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "בעל הרכב", readOnly: true },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Owner,
          callback: function($$v) {
            _vm.$set(_vm.model, "Owner", $$v)
          },
          expression: "model.Owner"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "היטל נהג", readOnly: true },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.DriverAmount,
          callback: function($$v) {
            _vm.$set(_vm.model, "DriverAmount", $$v)
          },
          expression: "model.DriverAmount"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "יתרה לתשלום", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.CurrentAmount,
          callback: function($$v) {
            _vm.$set(_vm.model, "CurrentAmount", $$v)
          },
          expression: "model.CurrentAmount"
        }
      }),
      _c("FieldInlineDateTime", {
        attrs: { label: "תאריך יצירה", readOnly: true },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.CreateDate,
          callback: function($$v) {
            _vm.$set(_vm.model, "CreateDate", $$v)
          },
          expression: "model.CreateDate"
        }
      }),
      _c("FieldInlineDate", {
        attrs: { label: "תאריך אחרון לתשלום", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.LastPayDate,
          callback: function($$v) {
            _vm.$set(_vm.model, "LastPayDate", $$v)
          },
          expression: "model.LastPayDate"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "כתובת", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Address,
          callback: function($$v) {
            _vm.$set(_vm.model, "Address", $$v)
          },
          expression: "model.Address"
        }
      }),
      _c("FieldInlineTextArea", {
        attrs: { label: "תיאור המקרה", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.Reason,
          callback: function($$v) {
            _vm.$set(_vm.model, "Reason", $$v)
          },
          expression: "model.Reason"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "תקנה", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.RelevantLaw,
          callback: function($$v) {
            _vm.$set(_vm.model, "RelevantLaw", $$v)
          },
          expression: "model.RelevantLaw"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "מזהה עיריה", "read-only": _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.MuniIdNumber,
          callback: function($$v) {
            _vm.$set(_vm.model, "MuniIdNumber", $$v)
          },
          expression: "model.MuniIdNumber"
        }
      }),
      _c("FieldInlineDate", {
        attrs: { label: "תאריך אימות", readOnly: _vm.readOnly },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.VerificationDate,
          callback: function($$v) {
            _vm.$set(_vm.model, "VerificationDate", $$v)
          },
          expression: "model.VerificationDate"
        }
      }),
      _vm.model.AddressLat && _vm.model.AddressLon
        ? _c("iframe", {
            staticStyle: { border: "0" },
            attrs: {
              src:
                "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3381." +
                _vm.model.AddressLon +
                "!2d" +
                _vm.AddressLat +
                "!3d32.05980746277334!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4b06457c8b43%3A0xc2a768266f1f7c72!2z15PXqNeaINeU16nXnNeV150gMTMxLCDXqtecINeQ15HXmdeRINeZ16TXlQ!5e0!3m2!1siw!2sil!4v1614160420394!5m2!1siw!2sil",
              width: "100%",
              height: "100",
              allowfullscreen: "",
              loading: "lazy"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }